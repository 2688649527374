// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/bg_ons.jpg";
import IframeVIdeo from "../../components/iframe_video/iframe_video";
import faqjson from "../../misc/ons_related/faq.json";
import slick_items from "../../misc/ons_related/slick_items_ons.json";
import Image from "../../components/image/image";
import {SalWrapper} from "react-sal";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link} from "gatsby";

const pageTitle = 'Invertí en Obligaciones Negociables desde tu cuenta Balanz';
const pageDesc = 'Comenzá a invertir en ONs de alta calidad crediticia de las empresas más importantes. Conocé cómo comprar Obligaciones Negociables en Argentina.';

let text = `Creemos que existen tantas inversiones como inversores, <br/><span class='highlightsbold'> te presentamos Obligaciones Negociables.</span>`;

const OnsPage = () => {
  //const [mobile, setMobile] = useState(false);
    const [xs, setXs] = useState(false);
    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 6}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };
    useEffect(() => {
        apiRequest
            .get("/v1/faq/6")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })
                })
                setFaq((old) => ({...old, list: data}));

            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
  const page = (
    <>
        <Helmet>
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué es una Obligación Negociable?",
                        "description": "Es un instrumento que se comercializa en el mercado de capitales tanto local como internacional.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=pp3-lANqgQo",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/obligaciones-negociables/",
                        "embedUrl": "https://balanz.com/inversiones/obligaciones-negociables/"
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en Obligaciones Negociables?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=XpjNQRWzM-g",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/obligaciones-negociables/",
                        "embedUrl": "https://balanz.com/inversiones/obligaciones-negociables/"
                    }
                `}
            </script>
        </Helmet>
        <ProductBanner
          bannerContainerClassName="bannerOns"
          bannerTrasparecy="bannerMask"
          productTitle="Obligaciones<br/>Negociables"
          bannerImage={Banner}
          bannerImageClassName="onsBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
    <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué es una Obligación Negociable?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Es un instrumento que se comercializa en el mercado de capitales tanto local como internacional. Es un  activo de renta fija, ya que representa una promesa cierta de repago futuro tanto de su capital como del interés. Es una fuente de financiamiento para la empresa emisora y una posibilidad de inversión para los inversores.
                            </p>
                            <div className="pt-4 text-center"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="300"
                            >
                                <Button id="ons_invertir_1" variant="secondary" text="Invertir en ONs" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
            <Container
                data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="200"
            >
                <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/pp3-lANqgQo"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
                <Row className="gx-5 gy-4 pt-3 pt-sm-2 pt-md-3 pt-md-4 pt-lg-5">
                    <Col xs={12}>
                        <p className="product-text">
                            La rentabilidad de los flujos futuros es conocida al momento de la compra. Los mismos están compuestos por la devolución del capital sumado a un interés que será abonado con la regularidad que se establezca en las condiciones iniciales de emisión.<br/>Quien invierte en ONs, compra parte de la deuda de una empresa; en vez de convertirse en socio se convierte en acreedor.
                 </p>
                    </Col>
                </Row>
                {/*<Row>
                    <div className="text-center pt-4 p-lg-3">
                        <Button variant="secondary" text="Ver ONs"/>
                    </div>
                </Row>*/}
            </Container>
        </section>
        <section className="fullwidth razones"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                 <Row>
                    <h2 className="mt-2">5 razones para invertir en Obligaciones Negociables</h2>

                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>
        <section className="fullwidth emisiones">
            <Container>
                <Row className="gx-5 gy-4 pt-1 pt-sm-2 pt-md-3">
                    <Col xs={12}>
                        <p className="product-text">
                            En Balanz llevamos efectuadas más de 400 emisiones, lo que nos convierte en protagonistas del Mercado de Capitales Argentino.<br/><br/>
                            <strong>Finalizamos el 2022 siendo la Entidad No Bancaria con mayor cantidad de emisiones de Obligaciones Negociables del año, con una participación en el 35% de las emisiones del mercado.</strong><br/><br/>

                            Te presentamos algunas de las empresas que confían en nosotros a la hora de colocar sus Obligaciones Negociables:
                        </p>
                    </Col>
                </Row>
                <Row className="g-0 icn-emisiones pt-">

                    <div className="d-flex flex-wrap justify-content-center py-3">
                        <Image style={{ width: "220px"}}
                               className="group-images  mx-3 mx-lg-4 my-5 pcr"
                               src="A_A_2000.png"
                               alt="Aeropuertos Argentina"
                        />
                        <Image style={{ width: "170px"}}
                               className="group-images  mx-3 mx-lg-4 my-5 cresud"
                               src="icn_cresud.png"
                               alt="Cresud"
                        />
                        <Image style={{ width: "80px"}}
                               className="group-images   mx-3 mx-lg-4 my-5 johndeere"
                               src="C_G_C.png"
                               alt="CGC"
                        />
                        <Image style={{ width: "170px"}}
                               className="group-images   mx-3 mx-lg-4 my-5  telecom"
                               src="icn_telecom.png"
                               alt="Telecom"
                        />
                        <Image style={{ width: "215px"}}
                               className="group-images  mx-3 mx-lg-4 my-5 pampa"
                               src="icn_pampa_energia.png"
                               alt="Pampa Energía"
                        />
                        <Image style={{ width: "170px"}}
                               className="group-images mx-3 mx-lg-3 my-5 vista"
                               src="C_N_H.png"
                               alt="CNH"
                        />
                        <Image style={{ width: "195px"}}
                               className="group-images  mx-3 mx-lg-3 my-5 genneia"
                               src="icn_genneia.png"
                               alt="Genneia"
                        />
                        <Image style={{ width: "244px"}}
                               className="group-images mx-3 mx-lg-3 my-5 ledesma"
                               src="icn_ledesma.png"
                               alt="Ledesma"
                        />
                        <Image style={{ width: "90px"}}
                               className="group-images mx-3 mx-lg-3 my-5 arcor"
                               src="IRS_BIG.png"
                               alt="IRSA"
                        />
                        <Image style={{ width: "170px"}}
                               className="group-images mx-3 mx-lg-3 my-5 ypg"
                               src="icn_ypf_luz.png"
                               alt="YPF Luz"
                        />
                    </div>
                </Row>
            </Container>
        </section>
        <section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en Obligaciones Negociables?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra
                          plataforma. <br/>Te recomendamos conocer tu perfil de
                          inversor y analizar junto con tu asesor, si es necesario,
                          cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button id="ons_invertir_2" variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir en ONs" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                      src="https://www.youtube.com/embed/XpjNQRWzM-g"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 {/*<Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>*/}
             </Container>
        </section>
        <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Obligaciones Negociables? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>
        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'ons-faq'}/>
                </Row>
              </Container>
        </section>
    </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"obligaciones-negociables"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default OnsPage;
